import { BalHeading, BalText } from "@baloise/ds-react";
import { Form } from "@baloise-cfa/form-renderer-frontend";
import { graphql, navigate } from "gatsby";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import { Footer, Navigation } from "@modules/shared/components";
import { PageNames } from "@modules/shared/enums";
import { getNavigationPages } from "@modules/shared/helpers";
import { AppState, appState } from "@modules/shared/state";
import { PageProps } from "@modules/shared/types";
import { beforeWeStartFormConfig, SimulatorLayout } from "@modules/simulator";

const BeforeWeStartPage: React.FC<PageProps> = ({ pageContext }) => {
    const { foundPage: page, ownPageObjects: allPages, language } = pageContext;
    const [appData, setAppData] = useRecoilState<AppState>(appState);
    const { nextPage, prevPage } = getNavigationPages(allPages, page);
    const alternativeNextPage = allPages.find(
        (tab) => tab.name === PageNames.CarDescriptionProfessional,
    );
    const { t } = useTranslation();

    const handleSubmit = (values: AppState): void => {
        setAppData(values);

        if (
            values.BeforeWeStart?.lightLoadVehicle &&
            values.BeforeWeStart?.professionalUsage &&
            alternativeNextPage
        ) {
            navigate(alternativeNextPage.paths[language]);
            return;
        }

        if (nextPage) {
            navigate(nextPage.paths[language]);
        }
    };

    const handleOnChange = (values: AppState): void => {
        setAppData(values);
    };

    return (
        <SimulatorLayout
            title={t("simulator.beforewestart.title")}
            page={page}
            language={language}
            allPages={allPages}
        >
            <main className="container is-compact mt-large flex flex-direction-column">
                <div className="flex-1">
                    <div className="intro">
                        <BalHeading level="h1">
                            {t("simulator.beforewestart.title")}
                        </BalHeading>
                        <BalText>{t("simulator.beforewestart.intro")}</BalText>
                    </div>

                    <Form
                        formClassName="mb-medium"
                        scrollToFieldError
                        errorMessageCardTitle={
                            t("all.errormessage.title") as string
                        }
                        errorMessageCardSubTitle={
                            t("all.errormessage.text") as string
                        }
                        formContext={{
                            t,
                            nameSpace: PageNames.BeforeWeStart,
                            fieldWrapper: {
                                optionalLabel: "all.optional",
                            },
                        }}
                        initialValues={appData}
                        fields={beforeWeStartFormConfig.fields}
                        onSubmit={handleSubmit}
                        onChange={handleOnChange}
                    >
                        <Navigation
                            t={t}
                            language={language}
                            prevPage={prevPage}
                            nextPage={nextPage}
                            nextPageBtnText="simulator.beforewestart.nextstep"
                        />
                    </Form>
                </div>
                <Footer />
            </main>
        </SimulatorLayout>
    );
};

export default BeforeWeStartPage;

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
